export default class StorageService {
    constructor() {}

    public static setItem(params: { name: string; data: any }, days?: number): void {
        this.setCookie(params.name, params.data, days);
    }

    public static getItem(params: string) {
        return this.getCookie(params);
    }

    public static removeItem(params: string): void {
        this.removeCookie(params);
    }

    private static setCookie(name: string, value: any, days?: number) {
        let expires: string;

        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        } else {
            expires = '';
        }

        document.cookie = `${name}=${value}${expires}; path=/; domain=${document.location.hostname}; SameSite=Strict; secure`;
    }

    private static getCookie(name: string) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];

            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }

        return null;
    }

    private static removeCookie(name: string) {
        this.setCookie(name, '', -1);
    }
}
