declare const Analytics: any;

import StorageService from './storage';

export class GDPR {
    COOKIE_TEMPORARY_NAME: string = 'cookiePolicyRequested';

    isGDPRAccepted: any = StorageService.getItem(this.COOKIE_TEMPORARY_NAME);

    elm: HTMLElement | null = document.querySelector('.js-module__gdpr');
    elmVisibleClass: string = 'module__gdpr--visible';
    btnAccept: HTMLElement | null = document.querySelector('.js-module__gdpr-accept');
    btnPolicy: HTMLElement | null = document.querySelector('.js-module__gdpr-policy');

    constructor() {
        this.init();
    }

    private init() {
        if (this.elm && this.elm.parentNode) {
            if (this.isGDPRAccepted) {
                this.elm.parentNode.removeChild(this.elm);
            } else {
                this.elm.classList.add(this.elmVisibleClass);

                StorageService.setItem({
                    name: this.COOKIE_TEMPORARY_NAME,
                    data: 1,
                });

                this.btnPolicy?.addEventListener('click', () => {
                    StorageService.removeItem(this.COOKIE_TEMPORARY_NAME);
                });

                this.btnAccept?.addEventListener('click', (e: Event) => {
                    e.preventDefault();

                    this.elm?.classList.remove(this.elmVisibleClass);

                    if (typeof Analytics === 'object' && typeof Analytics.set === 'function') {
                        Analytics.set('disabled', false);
                        Analytics.init();
                    }

                    setTimeout(() => {
                        this.elm?.parentNode?.removeChild(this.elm);
                    }, 250);

                    return false;
                });
            }
        }
    }
}

window.addEventListener('DOMContentLoaded', (e: Event) => {
    return new GDPR();
});
